<template>
  <v-layout column>
    <LabelTextField
      label="Oś głowa-tułów"
      placeholder="Wzmożone lub obniżone napięcie..."
      :rules="[maxlengthRule]"
      :value.sync="value.head_torso_axis"
      :disabled="disabled"
    ></LabelTextField>
    <LabelTextField
      label="Kończyny górne i dolne"
      placeholder="Wzmożone lub obniżone napięcie..."
      :rules="[maxlengthRule]"
      :value.sync="value.upper_lower_limbs"
      :disabled="disabled"
    ></LabelTextField>
    <LabelTextField
      label="Ocena mięśni brzucha i kresy"
      placeholder="Kolki, ulewania, chlustania..."
      :rules="[maxlengthRule]"
      :value.sync="value.abdominal_border_muscles"
      :disabled="disabled"
    ></LabelTextField>
    <LabelTextField
      label="Ocena jamy ustnej"
      placeholder="Zaburzenie połykania, ssania..."
      :rules="[maxlengthRule]"
      :value.sync="value.oral_cavity"
      :disabled="disabled"
    ></LabelTextField>
    <LabelTextField
      label="Motoryka duża"
      placeholder="Pełzanie, czworakowanie, siadanie..."
      :rules="[maxlengthRule]"
      :value.sync="value.gross_motor_skills"
      :disabled="disabled"
    ></LabelTextField>
    <LabelTextField
      label="Motoryka mała"
      placeholder="Chwyt ręki..."
      :rules="[maxlengthRule]"
      :value.sync="value.fine_motor_skill"
      :disabled="disabled"
    ></LabelTextField>
    <LabelTextField
      label="Sensoryka"
      placeholder="Nadwrażliwość, płaczliwość..."
      :rules="[maxlengthRule]"
      :value.sync="value.sensorcis"
      :disabled="disabled"
    ></LabelTextField>
    <LabelTextField
      label="Analiza strukturalna"
      placeholder="Stopy końsko-szpotawe, ocena kolan..."
      :rules="[maxlengthRule]"
      :value.sync="value.structural_analysis"
      :disabled="disabled"
    ></LabelTextField>
    <LabelTextField
      label="Inne"
      placeholder="Inne..."
      :rules="[maxlengthRule]"
      :value.sync="value.other"
      :disabled="disabled"
    ></LabelTextField>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LabelTextField: () => import("@/components/LabelTextField"),
  },
  methods: {
    maxlengthRule(text) {
      if (!text) {
        return true;
      }
      return text.length < 2000 || `Maksymalna ilość znaków to 2000, aktualnie masz ${text.length}.`;
    },
  },
};
</script>